@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');


html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  background-color: rgb(241 245 249 / 1) !important;
}

body {
  margin: 0;
}

code {
  font-family: 'Poppins', sans-serif !important;
}



/* width */
::-webkit-scrollbar {
  width: 7.5px;
  height: 7.5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #263238;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}